import $ from 'jquery';
window.$ = window.jQuery = $;

// Styles SCSS
import '../sass/home.scss';

// LazyLoad
import lazyLoadInit from './lazyload-init';
lazyLoadInit();

import 'slick-carousel';

import 'jquery.marquee';

import './utils/fancybox';

// pushbar
import Pushbar from './utils/pushbar';

$(document).ready(() => {

	console.log("home_js");

	jQuery('.translate option:contains("Select Language")').text('langues');
	jQuery('.translate option:contains("English")').text('EN');
	jQuery('.translate option:contains("French")').text('FR');
	jQuery('.translate option:contains("Spanish")').text('ES');
	jQuery('.translate option:contains("FR")').attr('selected', true);

	$('.header__bandeau__home').slick({
		dots: true,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 3000,
		speed: 2000,
		pauseOnHover: false,
		arrows: true,
		prevArrow: '<div class="prevArrow"><i class="far fa-chevron-left"></i><span class="sr-only">Prev</span></div>',
		nextArrow: '<div class="nextArrow"><i class="far fa-chevron-right"></i><span class="sr-only">Next</span></div>',
	});

	$('.actualites__slide').slick({
		slidesToShow: 2,
		slidesToScroll: 1,
		dots: true,
		infinite: true,
		arrows: false,
		autoplay: false,
		speed: 500,
		responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
      }
    }]
	});

	$('.agenda__slide').slick({
		slidesToShow: 2,
  	slidesToScroll: 1,
		dots: false,
		infinite: true,
		arrows: true,
		prevArrow: '<div class="prevArrow"><i class="fal fa-chevron-left"></i><span class="sr-only">Prev</span></div>',
		nextArrow: '<div class="nextArrow"><i class="fal fa-chevron-right"></i><span class="sr-only">Next</span></div>',
		autoplay: false,
		speed: 2000,
		responsive: [{
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
      }
    }]
	});

	new Pushbar({
		blur: true,
		overlay: true,
	});
});

/////////////////////////////////////////////////

/* Useful function for adding the em_ajax flag to a url, regardless of querystring format */
var em_ajaxify = function (url) {
	if (url.search('em_ajax=0') != -1) {
		url = url.replace('em_ajax=0', 'em_ajax=1');
	} else if (url.search(/\?/) != -1) {
		url = url + '&em_ajax=1';
	} else {
		url = url + '?em_ajax=1';
	}
	return url;
};
